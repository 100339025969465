<template>
  <div class="post">
    <van-list
      v-model="loading"
      :finished="isFinished"
      finished-text="没有更多了"
      @load="scrollToEnd"
    >
    <post-item v-if="post.id" @changeDyItem="changeDyItem" type="detail" class="post-info" :post="post" />
      <h4 v-if="post.id" class="comment-title">最新评论（{{ post.comment_count }}）</h4>

      <div class="list">
        <Comment @liked="liked" v-for="(item, index) in list" :index="index" :comment="item" :key="index" />
      </div>
    </van-list>
    <form @submit.prevent="comment" class="form border-top-1px view flex-row">
      <input ref="input" v-model="commText" @blur="inputBlur" type="text" placeholder="发布评论" class="m-r-40">
      <div class="view align-center justify-center">
        <van-rate v-model="score" :size="10" class="m-b-10"/>
        <span v-if="score >= 4" class="fz-12">精彩绝伦</span>
        <span v-if="score > 2 && score < 4 " class="fz-12">不怎么样嘛</span>
        <span v-if="score <= 2" class="fz-12">差</span>
      </div>

    </form>
  </div>
</template>

<script>
import PostItem from '../../../components/post-item/post-item'
import Comment from '../../../components/comment/comment'
import { blurInput } from '../../../common/js/mixins'
import { post, get } from '../../../common/js/http'

export default {
  mixins: [blurInput],
  name: 'postZT',
  components: {
    PostItem,
    Comment
  },
  data() {
    return {
      loading: false,
      score: 5,
      post: {
        user: {
          profile: {}
        },
        liked: false,
        collected: false
      },
      list: [],
      isFinished: true,
      page: 1,
      commText: ''
    }
  },
  created() {
    this.getDetail()
    this.getComments()
    this.exposure()
  },
  methods: {
    exposure() {
      // post('/api/Moment/addWatchLog', {
      //   momentid: this.$route.params.id,
      //   hideLoading: true
      // })
    },
    changeDyItem({ index, item }) {
      this.post = item
    },
    comment() {
      if (!this.commText) {
        this.$toast.fail('请输入评论内容')
        return
      }
      clearTimeout(this.commentTimer)
      this.commentTimer = setTimeout(() => {
        if (this.commenting) return
        this.commenting = true
        post('/course/comment', {
          course_id: this.$route.params.id,
          comment: this.commText,
          score: this.score
        })
          .then(({ data: { data } }) => {
            // this.list.unshift(data)
            this.getComments()
            this.commenting = false
            this.commText = ''
            this.$refs.input.blur()
          })
      }, 200)
    },
    likeMoment() {
      // post('/api/Moment/likeMoment', {
      //   momentid: this.post.id
      // })
      //   .then(() => {
      //     this.post.liked = true
      //   })
    },
    liked({ data, index }) {
      this.$set(this.list, index, {
        ...this.list[index],
        liked: true,
        ...data
      })
    },
    scrollToEnd() {
      this.page++
      this.getComments()
    },
    getDetail() {
      // post('/webapi/Moment/getMomentInfo', {
      //   id: this.$route.params.id
      // })
      //   .then(({ data: { data } }) => {
      //     data.liked = data.liked ? data.liked : false
      //     data.collected = data.collected ? data.collected : 0
      //     this.post = data
      //     this.$nextTick(() => {
      //       this.$refs.scroll.refresh()
      //     })
      //   })
    },
    collection(type) {
      post('/api/Moment/collectMoment', {
        momentid: this.post.id,
        type
      })
        .then(() => {
          this.post.collected = type
        })
    },
    getComments() {
      get('/course/comments', {
        page: this.page,
        course_id: this.$route.params.id,
        hideLoading: this.page > 1,
        size: 10
      })
        .then(({ data: { data: { list } } }) => {
          this.isFinished = list.length < 10
          this.list = this.list.concat(list)
          this.$nextTick(() => {
            this.loading = false
          })
        })
    }
  }
}
</script>

<style scoped lang="stylus">
.post
  max-height calc(100vh - 700px)
  overflow-y: auto
  padding-bottom 95px
  box-sizing border-box
  .post-info
    margin-top 30px
  .comment-title
    margin-bottom 22px
    padding 0 30px
    font-weight bold
    font-size 24px
    color #000000
  .list
    padding-left 30px
  .form
    position fixed
    left 0
    bottom 0
    width 100%
    height 95px
    box-sizing border-box
    padding 0 30px
    display flex
    align-items center
    &>input
      flex 1
      background #F5F5F5
      height 58px
      line-height 58px
      padding-left 22px
      border-radius 29px
      font-size 28px
      outline none
    &>img
      width 44px
      height 44px
      margin-left 46px
</style>
