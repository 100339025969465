<template>
  <div class="about" style="padding-top: 44px;">
    <back-header :title="course.title" />
    <div class="test-videojs" style="width: 100%;height: 100%;position: relative;">
        <video id="videoPlayer" class="video-js" muted></video>
    </div>

    <div>
      <van-tabs v-model="activeTab">
        <van-tab title="目录" name="1">
          <van-collapse v-model="catalogueActive" :border="false">
            <van-collapse-item :title="`0${index + 1}. ${item.chapters.title}`" :name="String(index)" v-for="(item,index) in courseList" :key="index" >
              <div v-for="(data,key) in item.items" :key="key" class="c-item point" @click="changeVideo(data)">
                  <div class="view flex-row align-center m-b-10" :class="data.breakpoint ? 'c_008':''">
                    <voiceDot v-if="data.breakpoint" class="m-r-15"/>
                    <i class="iconfont icon-shipincundang c_008 fz-12 m-r-15" v-else></i>

                    {{ key + 1}}. {{ data.title }}
                  </div>
                  <div class="view flex-row align-center justify-between">
                    <div class="fz-12 c_666 m-t-5 m-b-5 m-l-25">{{ fmtTime(data.duration) }}分钟</div>
                    <div class="c_ff5 fz-14" v-if="data.breakpoint">播放中..</div>
                    <div class="c1 fz-14" v-else>观看</div>
                  </div>

                </div>
            </van-collapse-item>
          </van-collapse>
        </van-tab>
        <van-tab title="简介" name="2">
                <div v-if="course.desc" v-html="course.desc" class="p-t-30 p-l-30 p-r-30 p-b-30"></div>
                <van-empty description="暂无更多描述" v-else></van-empty>
        </van-tab>
        <van-tab title="评价" name="3">
            <postZt></postZt>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import BackHeader from '../../components/back-header/back-header'
import { mapState, mapMutations } from 'vuex'
import { post, get } from '../../common/js/http'
import voiceDot from '@/components/play-icon/play-icon'
import dayjs from 'dayjs'
import postZt from './postZT/post'

export default {
  name: 'videoPlaye',
  components: {
    BackHeader,
    voiceDot,
    postZt
  },
  data() {
    return {
      catalogueActive: ['0'],
      player: false,
      lock: false,
      title: '',
      play_time: [],
      playDetail: {
        lessons: {},
        user: {},
        lecturer: {}
      },
      courseList: [],
      course: {},
      postData: {},
      activeTab: 1
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose() // Removing Players,该方法会重置videojs的内部状态并移除dom
    }
  },
  async created () {
    await this.getLecturerInfo()
    await this.getCourseDetail()
  },
  computed: {
    ...mapState(['userInfo']),
    isIos() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      return isiOS
    }
  },
  methods: {
    ...mapMutations(['wxShare']),
    fmtTime: function (val) {
      console.log('时长==================================>' + val)
      return dayjs.unix(val).format('mm') || '？？'
    },
    changeVideo(data) {
      this.playDetail = {
        playback: {
          url: data.url,
          cover: this.course.cover,
          lesson_id: data.id,
          course_id: data.course_id
        }
      }

      this.courseList = this.courseList.map(item => {
        item.items.map(da => {
          if (da.id == data.id) {
            da.breakpoint = true
          } else {
            da.breakpoint = false
          }
        })
        return item
      })

      this.player.src({ src: this.playDetail.playback.url, type: 'application/x-mpegURL' })
      this.player.load()
      this.setShare()
    },
    async getCourseDetail() {
      const { id } = this.$route.params
      const res = await get('course/show', {
        course_id: id
      })
      this.course = res.data.data.course
      document.title = this.course.title
      const courseList = res.data.data.chapters.map(item => {
        return {
          chapters: item,
          items: res.data.data.items.filter(data => {
            return data.chapter_id == item.chapter_id
          })
        }
      })

      this.play_time = res.data.data.play_time

      if (this.play_time && this.play_time.length > 0) {
        let breaks = []
        courseList.forEach((item, idx) => {
          item.items.forEach((data, k) => {
            if (data.id == this.play_time[0]) {
              // 上次播放到这里
              data.breakpoint = true
              breaks = [idx, k]
            }
          })
        })
        this.playDetail = {
          playback: {
            url: courseList[breaks[0]].items[breaks[1]].url,
            cover: this.course.cover,
            lesson_id: courseList[breaks[0]].items[breaks[1]].id,
            course_id: courseList[breaks[0]].items[breaks[1]].course_id
          }
        }
      } else {
        // 最新播放
        courseList[0].items[0].breakpoint = true
        this.playDetail = {
          playback: {
            url: courseList[0].items[0].url,
            cover: this.course.cover,
            lesson_id: courseList[0].items[0].id,
            course_id: courseList[0].items[0].course_id
          }
        }
      }

      this.courseList = courseList
      this.catalogueActive = this.courseList.map((it, i) => String(i))
      this.setShare()
      this.initPlayer()
    },
    setShare() {
      this.playDetail.title = this.course.title
      this.playDetail.user = this.lectureUserInfo
      this.playDetail.cover = this.playDetail.playback.cover
      this.playDetail.desc = `来自：${this.lectureUserInfo.nickname}的视频分享课`
      this.wxShare(this.playDetail)
    },
    async getLecturerInfo() {
      const { user_id } = this.$route.query
      const res = await get('lecturer/show', {
        user_id
      })
      this.lectureUserInfo = res.data.data
    },
    doPlay() {
      if (this.play_time && this.play_time.length > 0) {
        document.querySelector('.vjs-tech').currentTime = this.play_time[1]
        // this.player.seek(this.play_time[1]);
      }
    },
    initPlayer() {
      const options = {
        aspectRatio: '16:9',
        language: 'zh-cn',
        fluid: true,
        loop: false,
        autoplay: true, // 设置自动播放
        controls: true, // 显示播放的控件
        inactivityTimeout: 0,
        poster: this.playDetail.playback.cover, // 封面
        nativeControlsForTouch: false,
        bigPlayButton: true,
        controlBar: { // 设置控制条组件
          /* 设置控制条里面组件的相关属性及显示与否
                    'currentTimeDisplay':true,
                    'timeDivider':true,
                    'durationDisplay':true,
                    'remainingTimeDisplay':false,
                    volumePanel: {
                      inline: false,
                    }
                    */
          /* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
          children: [
            { name: 'playToggle' }, // 播放按钮
            { name: 'currentTimeDisplay' }, // 当前已播放时间
            { name: 'progressControl' }, // 播放进度条
            { name: 'durationDisplay' }, // 总时间
            { // 倍数播放
              name: 'playbackRateMenuButton',
              playbackRates: [0.5, 1, 1.5, 2, 2.5]
            },
            {
              name: 'volumePanel', // 音量控制
              inline: false // 不使用水平方式
            },
            { name: 'FullscreenToggle' } // 全屏
          ]
        },
        sources: [
          // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
          {
            src: this.playDetail.playback.url,
            type: 'application/x-mpegURL' // 告诉videojs,这是一个hls流
          }
        ]
      }
      // videojs的第一个参数表示的是，文档中video的id
      const _this = this
      this.player = this.$DPlayer('videoPlayer', options, function () {
        // _this
        console.log('onPlayerReady 中的this指的是：', this) // 这里的this是指Player,是由Videojs创建出来的实例             // 这里返回的是true
        this.on('timeupdate', function(val) { // 播放时间改变
          // console.log(val)
          if (!this.lock) {
            setTimeout(() => {
              this.lock = false
              post('/common/playtime', {
                type: 1,
                current_time: parseInt(document.querySelector('#videoPlayer').firstChild.currentTime),
                lesson_id: _this.playDetail.playback.lesson_id,
                course_id: _this.playDetail.playback.course_id
              })
            }, 10000)
          }
          this.lock = true
        })
        this.on('playing', function() {

        })
        _this.doPlay()
      })
    }

  }
}
</script>

<style scoped lang="less">
  .about {
    .test-videojs {
      width: 100%;
      height:420px;
    }
  }
/deep/ .van-tabs__line {
  background-color: #008C8C !important;
}
/deep/ .van-tabs {
  border-bottom: 1px solid #F0F1F5;
  padding-bottom: 5px;
}
.c-item {
  margin-bottom: 30px;
}
</style>
