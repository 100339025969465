<template>
    <div class="voice-playing">
        <div class="play1"></div>
        <div class="play2"></div>
        <div class="play3"></div>
    </div>
  </template>

  <script>

  export default {
    components: {

    },
    props: {
    },
    data() {
      return {

      }
    },
    methods: {

    }
  }
  </script>

  <style scoped lang="less">
  .voice-playing {
        height: 20px;
        width: 20px;
        display: flex;
        /* 底部对齐，实现从下往上的动画效果 */
        align-items: flex-end;
        justify-content: space-between;
    }
    .play1 {
        width: 6px;
        background-color: rgba(13,181,122,1);
        animation: playing1 1s linear infinite alternate;
    }
    .play2 {
        width: 6px;
        background-color: rgba(13,181,122,1);
        animation: playing2 1s linear infinite alternate;
    }
    .play3 {
        width: 6px;
        height: 20px;
        background-color: rgba(13,181,122,1);
        animation: playing3 1s .5s linear infinite alternate;
    }
    @keyframes playing1 {
        0%{
            height: 2px;
        }
        100%{
            height: 12px;
        }
    }
    @keyframes playing2 {
        0%{
            height: 12px;
        }
        100%{
            height: 2px;
        }
    }
    @keyframes playing3 {
        0%{
            height: 2px;
        }
        100%{
            height: 12px;
        }
    }
  </style>
