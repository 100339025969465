<template>
  <div class="comment border-bottom-1px" @click="$download()">
    <img :src="(comment && comment.user && comment.user.avatar) || $store.state.defaultAvatar" alt="">
    <div class="info">
      <h6>{{ comment && comment.user && comment.user.nickname }}  </h6>
      <p>{{ comment && comment.comment }}</p>
      <div class="data-num view flex-row align-center justify-between">
        <span>{{ comment && comment.created_at }}</span>
        <van-rate :value="comment && comment.score" :size="10" class="m-r-30"/>
      </div>
    </div>
  </div>
</template>

<script>
import levelIcon from '../../common/js/level-icon'
import { post } from '../../common/js/http'

export default {
  name: 'comment',
  components: {
  },
  props: {
    comment: {
      type: Object,
      default() {
        return {
          user: {}
        }
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      levelIcon
    }
  },
  methods: {
    like() {
      post('/webapi/Moment/likeComment', {
        commentid: this.comment.id
      })
        .then(({ data: { data } }) => {
          this.$emit('liked', {
            data,
            index: this.index
          })
        })
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../common/style/variable.styl"
.comment
  display flex
  padding-bottom 25px
  margin-bottom 25px
  &>img
    width 80px
    height 80px
    border-radius 50%
    margin-right 20px
  .info
    flex 1
    &>h6
      margin-top 25px
      margin-bottom 27px
      display flex
      align-items center
      font-size 28px
      color #666666
      &>img
        margin-left 15px
        margin-right 7px
        width 42px
        height 26px
    &>p
      margin-bottom 32px
      font-size 30px
      color #000000
    .data-num
      display flex
      align-items center
      &>span
        font-size 24px
        color #B4B4B4
      .count
        margin-left 26px
        padding 0 17px
        height 38px
        line-height 38px
        border-radius 19px
        font-size 24px
        color #FF6600
        background #E6D6FF
    .icon-num
      margin-right 30px
      display flex
      align-items center
      font-size 24px
      color #343434
      &.active
        color $theme-color
      &>i
        font-weight 500
        margin-right 13px
        vertical-align bottom
        font-size 34px
</style>
